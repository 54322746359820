import { createSlice } from "@reduxjs/toolkit"
import Client from "shopify-buy"

const client = Client.buildClient({
  domain: "orchard-pig.myshopify.com",
  storefrontAccessToken: "74752521466405477f31dbad3271e25c",
})

const app = createSlice({
  name: "cart",
  initialState: {
    checkout: null,
  },
  reducers: {
    setState: (state, action) => {
      return { ...state, ...action.payload }
    },
  },
})

export const initializeCheckout = () => async dispatch => {
  const isBrowser = typeof window !== "undefined"
  const existingCheckoutID = null
  // const existingCheckoutID = isBrowser
  //   ? localStorage.getItem("shopify_checkout_id")
  //   : null TEMPORARY

  if (!existingCheckoutID) {
    createCart(dispatch)
  } else {
    try {
      let currentCheckout = await client.checkout.fetch(existingCheckoutID)
      if (currentCheckout.completedAt) {
        createCart(dispatch)
      }

      dispatch(
        setState({
          checkout: currentCheckout,
        }),
      )
    } catch (e) {
      createCart(dispatch)
    }
  }
}

const createCart = async dispatch => {
  const newCheckout = await client.checkout.create()
  localStorage.setItem("shopify_checkout_id", newCheckout.id)
  dispatch(
    setState({
      checkout: newCheckout,
    }),
  )
}

export const addToCart =
  ({ checkoutId, product }) =>
  async dispatch => {
    const cart = await client.checkout.addLineItems(checkoutId, [
      {
        variantId: product.shopifyId,
        quantity: product.quantity,
        customAttributes: product.personalisation
          ? {
              key: "Personalisation",
              value: product.personalisation,
            }
          : null,
      },
    ])
    dispatch(
      setState({
        checkout: cart,
      }),
    )
  }

export const updateLineItem =
  (checkoutID, lineItemID, quantity) => async dispatch => {
    const lineItemsToUpdate = [
      { id: lineItemID, quantity: parseInt(quantity, 10) },
    ]

    let updatedCart = await client.checkout
      .updateLineItems(checkoutID, lineItemsToUpdate)
      .then()

    dispatch(
      setState({
        checkout: updatedCart,
      }),
    )
  }

export const { setState } = app.actions

export default app
